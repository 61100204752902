/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useMemo } from 'react';
import { PostCommentRowContext } from '../context';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { Avatar } from '@mybridge/ui/avatar';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { Heading } from '@mybridge/ui/heading';
import { Text } from '@mybridge/ui/text';
import PostTiming from '../comment-timing';
import { PostActionsMenu } from '../comment-actions-menu';
import { useSelector } from 'react-redux';
import { CompanyDetailsPopover } from 'v4/components/company/company-details-popover';
import { UserDetailsPopover } from 'v4/components/user-details-popover';

export const PostCommentRowHeader = ({ showActions = true }) => {
  const { comment } = useContext(PostCommentRowContext);
  const { owner: author, company_page: company } = comment ?? {};
  const { position } = author ?? {};

  const userFullName = useMemo(() => getUserFullName(author), [author]);
  const userProfilePic = useMemo(() => getUserProfilePic(author), [author]);
  return (
    <>
      <HStack py={4} spacing={3} alignItems="flex-start">
        <Stack>
          <Avatar
            size="md"
            name={company ? company?.name : userFullName}
            src={company ? company?.image : userProfilePic}
          />
        </Stack>
        <HStack flex={1} alignItems="flex-start" justifyContent="space-between">
          <Stack pt={1} spacing={0}>
            <Box flex={1}>
              {company ? (
                <UserDetailsPopover company={company}>
                  <Heading
                    color="brandPrimary.500"
                    fontWeight="medium"
                    size="sm"
                  >
                    {company ? company?.name : userFullName}
                  </Heading>
                </UserDetailsPopover>
              ) : (
                <UserDetailsPopover user={author}>
                  <Heading
                    color="brandPrimary.500"
                    fontWeight="medium"
                    size="sm"
                  >
                    {company ? company?.name : userFullName}
                  </Heading>
                </UserDetailsPopover>
              )}

              <Text size="sm" color="brandGray.700">
                {company ? company?.main_industry : position}
              </Text>
              <PostTiming />
              {!position && <Stack mt={5} spacing={0} flex={1}></Stack>}
            </Box>
          </Stack>
          {showActions ? (
            <Stack>
              <PostActionsMenu />
            </Stack>
          ) : (
            ''
          )}
        </HStack>
      </HStack>
    </>
  );
};
